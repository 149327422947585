// src/helpers/adjustUrl.helper.ts
export const adjustUrl = (url: string): string => {
    if (typeof window !== "undefined" && window.location.hostname === "localhost") {
        try {
            const parsedUrl = new URL(url, window.location.origin);
            return parsedUrl.pathname + parsedUrl.search + parsedUrl.hash;
        } catch (e) {
            // If it's already a relative URL or invalid URL, return as is
            return url;
        }
    }
    return url;
};
