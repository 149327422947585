import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { cn } from "@danishagro/shared/src/helpers/classNames.helper";
import { DA_Button } from "@danishagro/shared/src/components/atoms/Button/Button.component";
import { DA_ButtonLink } from "@danishagro/shared/src/components/atoms/ButtonLink/ButtonLink.component";
import { DA_CartButton } from "@molecules/CartButton/CartButton.component";
import { DA_ContentBox } from "@danishagro/shared/src/components/atoms/ContentBox/ContentBox.component";
import { DA_DeliveryInfoModalContent } from "@templates/DeliveryInfoModal/DeliveryInfoModal.component";
import { DA_IconNames } from "@danishagro/shared/src/components/atoms/Icon/Icon.component";
import { DA_ImageSlider } from "@molecules/ImageSlider/ImageSlider.component";
import { DA_PriceTableLine } from "@molecules/PriceTable/PriceTable.component";
import { DA_QuantityPicker } from "@molecules/QuantityPicker/QuantityPicker.component";
import { DA_StockStatus } from "@atoms/StockStatus/StockStatus.component";
import { DA_Tab } from "@danishagro/shared/src/components/molecules/Tab/Tab.component";
import { DA_Title } from "@danishagro/shared/src/components/atoms/Title/Title.component";
import { DA_WishListButton } from "@molecules/WishListButton/WishListButton.component";
import { ModalSize, useModal } from "@danishagro/shared/src/contexts/modal.context";
import { useCart } from "@danishagro/shared/src/contexts/cart/cart.context";
import { useScreen } from "@danishagro/shared/src/contexts/screen.context";
import { DA_Accordion } from "@danishagro/shared/src/components/molecules/Accordion/Accordion.component";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import { useDynamicOverlay } from "@danishagro/shared/src/hooks/dynamicOverlay/useDynamicOverlay.hook";
import { useAppData } from "@danishagro/shared/src/contexts/appData.context";
import {
    DA_NewProduct,
    DA_NewVariantEntity,
} from "@danishagro/shared/src/interfaces/productv2.interface";
import { DA_InputWrapper } from "@danishagro/shared/src/components/atoms/InputWrapper/InputWrapper.component";
import { DA_Select } from "@danishagro/shared/src/components/atoms/Select/Select.component";
import { useNavigate } from "react-router-dom";
import { DA_Image } from "@danishagro/shared/src/components/atoms/Image/Image.component";
import { DA_Text } from "@danishagro/shared/src/components/atoms/Text/Text.component";
import { DA_InlineLink } from "@danishagro/shared/src/components/atoms/InlineLink/InlineLink.component";
import { useRelewise } from "@danishagro/shared/src/contexts/relewise.context";
import { DA_Tooltip } from "@danishagro/shared/src/components/atoms/Tooltip/Tooltip.component";
import { DA_Price } from "@atoms/Price/Price.component";
import { pushToDataLayer } from "@danishagro/shared/src/gtm/useTagManger";
import { formatNumber } from "@danishagro/shared/src/helpers/formatNumber.helper";
import { useProductTracker } from "@danishagro/shared/src/gtm/useProductsTracker";
import { usePage } from "@danishagro/shared/src/contexts/page.context";
import { DA_Tag } from "@danishagro/shared/src/components/atoms/Tag/Tag.component";
import { isGreaterThanZero } from "@danishagro/shared/src/helpers/greaterThanZero.helper";
import { DA_QuantityOnPalletOverlay } from "@danishagro/shared/src/modals/QuantityOnPalletShop/Overlay/Overlay.component";
import { useBottomSheet } from "@danishagro/shared/src/contexts/bottomSheet/bottomSheet.context";
import { DA_Modal } from "@danishagro/shared/src/components/molecules/Modal/Modal.component";
import { modalState } from "@danishagro/shared/src/enums/modalState.enum";
import {
    ProblemDetailsError,
    ProductResult,
    ProductsRecommendationCollectionBuilder,
    PurchasedWithProductBuilder,
} from "@relewise/client";
import ComponentErrorBoundary from "@danishagro/shared/src/components/molecules/ErrorBoundary/ComponentErrorBoundary.component";
import { DA_ProductItemSlider } from "@organisms/ProductItemSlider/ProductItemSlider.component";
import { useShortDescription } from "./components/hooks/useShortDescription.hook";
import { BottomSheetDeliveryInfo } from "./components/BottomSheetDeliveryInfo/BottomSheetDeliveryInfo.component";
import { DA_ProductDescription } from "./components/ProductDescription/ProductDescription.component";
import { BottomSheetSelect } from "./components/BottomSheetSelect/BottomSheetSelect.component";
import S from "./ProductDetails.module.scss";

export interface DA_ProductDetailsProps extends DA_NewProduct {
    priceLines?: DA_PriceTableLine[];
    stockStatus?: string;
    isQuickOrder?: boolean;
    onVariantChange?: (variantEntity: DA_NewVariantEntity) => void;
}

interface DA_TabRefInterface {
    showDescriptionTab: () => void;
}
interface DA_ProductDescriptionRefInterface {
    showFullDescription: () => void;
    scrollIntoView: () => void;
}

export const DA_ProductDetails = (props: DA_ProductDetailsProps) => {
    const [quantity, setQuantity] = useState(1);
    const { isFarmInTimeEnabled, showPrices } = useAppData();
    const [awaitingResponse, setAwaitingResponse] = useState(false);
    const [noProduct, _setNoProduct] = useState(false);
    const { customerNumber, currentCulture } = useAppData();
    const { getDictionaryString, getDictionaryItem } = useTranslations();
    const { addToCart } = useCart();
    const { showOverlay } = useDynamicOverlay();
    const { showModal } = useModal();
    const { getTracker, relewiseUser, allowTracking, getRecommender, mapProduct } = useRelewise();
    const { currentPathDetails } = usePage();
    const { isMobileOrTablet } = useScreen();
    const { presentBottomSheet } = useBottomSheet();
    const startOnVideos = useRef(location.hash.replace(/#/, "") === "video").current;
    const tabRef = useRef<DA_TabRefInterface>();
    const productDescriptionRef = useRef<DA_ProductDescriptionRefInterface>();
    const [quantityOnPalletValue, setQuantityOnPalletValue] = useState("");

    const variantGroupsLength = props.variantGroups?.length || 0;
    const [variantsComplete, setVariantsComplete] = useState(true);
    const [choosedValue, setChoosedValue] = useState(false);

    const [modalViewState, setModalViewState] = useState<modalState>(modalState.hide);
    const [numberOfPallets, setNumberOfPallets] = useState<number>(0);
    const [totalPalletQuantity, setTotalPalletQuantity] = useState<number>(0);
    const [relatedProducts, setRelatedProducts] = useState<ProductResult[] | null | undefined>();

    const navigate = useNavigate();
    const [selectedVariantEntity, setSelectedVariantEntity] = useState<{
        variantEntityId: string;
        url: string;
    }>();

    // Find if product has redirectUrl in fieldGroups data
    const hasProductRedirectUrl = (productDataProps) => {
        const productRedirectKey = "redirectUrl";
        // Check if the JSON data contains the fieldGroups array
        if (productDataProps.fieldGroups && Array.isArray(productDataProps.fieldGroups)) {
            // Iterate through each field group
            for (const fieldGroup of productDataProps.fieldGroups) {
                // Check if the field group name is "Skjulte Felter"
                if (fieldGroup.name && fieldGroup.name.toLowerCase() === "skjulte felter") {
                    // Check if the fields object contains the "Product Redirect Url" key
                    if (fieldGroup.fields && fieldGroup.fields[productRedirectKey]) {
                        // Check if the value of "Product Redirect Url" is not an empty string
                        return fieldGroup.fields[productRedirectKey].trim() !== "";
                    }
                }
            }
        }
        // Return false if "Product Redirect Url" is not found or is empty
        return false;
    };

    const settings = useMemo(
        () => ({
            language: "da",
            currency: "DKK",
            displayedAtLocation: "ProductDetails",
            user: relewiseUser(),
        }),
        [relewiseUser]
    );

    const puchasedWithProductBuilder = new PurchasedWithProductBuilder(settings)
        .setSelectedProductProperties({
            displayName: true,
            pricing: true,
            allData: true,
            brand: true,
            allVariants: true,
        })
        .setNumberOfRecommendations(10)
        .product({ productId: props.entityId });

    const relatedProductsBuilder = new ProductsRecommendationCollectionBuilder().addRequest(
        puchasedWithProductBuilder.build()
    );

    useEffect(() => {
        if (props.useRelewiseRelatedProducts) {
            getRecommender()
                .batchProductRecommendations(relatedProductsBuilder.build())
                .then((result) => {
                    setRelatedProducts(result.responses[0].recommendations);
                })
                .catch((error: ProblemDetailsError) => console.error(error));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (props.fieldGroups) {
            setQuantityOnPalletValue(
                props.fieldGroups.find((group) => group.id === "hiddenFields")?.fields
                    ?.quantityOnPallet || ""
            );
        }
    }, [props.fieldGroups]);

    // Get field from hidden fields
    const getFieldFromHiddenFields = (fieldName) =>
        props.fieldGroups.find((group) => group.id === "hiddenFields")?.fields?.[fieldName] || "";

    const redirectUrl = getFieldFromHiddenFields("redirectUrl");

    // Get special freight description and information from hidden fields
    const specialFreightDescription = getFieldFromHiddenFields("specialFreightDescription");
    const specialFreightOverlayText = getFieldFromHiddenFields("specialFreightInformation");
    const hasSpecialFreight = specialFreightOverlayText ? true : false;

    const deliveryLinkText = getDictionaryString(
        hasSpecialFreight ? "ProductPageDeliveryFeeLinkOverwrite" : "ProductPageDeliveryFeeLink"
    );
    const deliveryModalTitle = getDictionaryString(
        hasSpecialFreight ? "deliveryInfoOverwrite" : "deliveryInfo"
    );

    const hasQuantityOnPallet = isGreaterThanZero(quantityOnPalletValue);

    // Product is available to buy if it does not have a product redirect URL
    const isAvailableToBuy = !hasProductRedirectUrl(props);

    const shortDescriptions = useShortDescription(props.shortDescription, props.description);
    const descriptionAccordionRef = useRef<DA_ProductDescriptionRefInterface>();

    // State to manage selected variants
    const [selectedVariants, setSelectedVariants] = useState({});
    const [optionGroups, setOptionGroups] = useState(props.variantGroups);

    const productUrl = `${props.canonicalUrl}/${props.webName}`;

    const quantityAsInt = parseInt(quantityOnPalletValue);

    useEffect(() => {
        if (allowTracking()) {
            getTracker()?.trackProductView({
                productId: props.entityId,
                variantId: props.variantEntityId,
                user: relewiseUser(),
            });
        }
    }, [
        customerNumber,
        getTracker,
        relewiseUser,
        props.entityId,
        props.variantEntityId,
        allowTracking,
    ]);

    // Effect to pre-select variants if variantEntityId exists
    useEffect(() => {
        if (props.variantEntityId) {
            // Initialize selected variants object
            const selected = {};

            // Loop through variant groups
            props.variantGroups.forEach((group) => {
                // Find the option corresponding to the variantEntityId
                const selectedOption = Object.entries(group.options).find(([_key, value]) =>
                    value.entityIds.includes(props.variantEntityId)
                );

                if (selectedOption) {
                    // Set the selected variant option
                    selected[group.name] = selectedOption[1].value; // Use the value property of the selected option
                }
            });

            // Update state with selected variants
            setSelectedVariants(selected);
        }
    }, [props.variantEntityId, props.variantGroups]);

    // Function to handle variant selection
    const handleVariantChange = (group, option) => {
        // Update selected variants state
        setSelectedVariants((prevState) => ({
            ...prevState,
            [group]: option.value, // Use the value property of the selected option
        }));
    };

    const getIntersection = (arrayOfArrays) => {
        return arrayOfArrays?.reduce((acc, currentValue) => {
            return acc.filter((res) => currentValue?.find((value) => value === res));
        });
    };

    useEffect(() => {
        setOptionGroups(props.variantGroups);

        if (selectedVariants) {
            const result = [];

            // get ids for each selected item in selectedVariants
            Object.values(selectedVariants).forEach((value) => {
                // Loop through each variant group
                props.variantGroups.forEach((variantGroup) => {
                    const selectedOptionIds = Object.values(variantGroup.options).find(
                        (val) => val.value === value
                    )?.entityIds;

                    if (selectedOptionIds) {
                        result.push(selectedOptionIds);
                    }
                });
            });

            if (result.length > 1) {
                const allSelected = [];
                const optionGroups = [];
                result.map((r) => allSelected.push(...r));

                // Calculate disabled option state based on selection
                props.variantGroups.forEach((variantGroup) => {
                    const options = Object.values(variantGroup.options).map((opt) => {
                        return {
                            ...opt,
                            disabled: getIntersection([opt.entityIds, allSelected])?.length === 0,
                        };
                    });

                    optionGroups.push({
                        ...variantGroup,
                        options,
                    });
                });

                setOptionGroups(optionGroups);
            }

            // compare arrays in result
            if (result.length) {
                const [variantIntersection] = getIntersection(result);

                if (variantIntersection && variantIntersection !== props.variantEntityId) {
                    const variantUrl = props.urls[variantIntersection];
                    if (variantUrl) {
                        setSelectedVariantEntity({
                            variantEntityId: variantIntersection,
                            url: variantUrl,
                        });
                    }
                }
            }
        }
    }, [
        props.urls,
        props.variantEntityId,
        props.variantGroups,
        selectedVariants,
        variantGroupsLength,
    ]);

    useEffect(() => {
        if (selectedVariantEntity) {
            const url = selectedVariantEntity.url;
            setSelectedVariantEntity(undefined); // otherwise router gets stuck on this page

            if (props.isQuickOrder && props.onVariantChange) {
                props.onVariantChange(selectedVariantEntity);
            } else {
                // TODO: figure out disabled items
                navigate(url, { replace: true });
            }

            setVariantsComplete(true);
        }
    }, [navigate, props, selectedVariantEntity]);

    // Convert formatted price string to float and ensure two decimals
    const formattedPriceString = formatNumber(props?.price?.priceWithoutVat, currentCulture, {
        decimals: 2,
    });
    const priceWithTwoDecimalsString = parseFloat(formattedPriceString.replace(",", ".")).toFixed(
        2
    );

    const image = useRef(props.media && props.media.length > 0 ? props.media[0].url : null).current;

    // Extract the category from the URL
    const category = props?.canonicalUrl?.split("/").slice(0, -1).join("_").replace(/^_/, "");

    // Get the name of the last item in the currentPathDetails array
    const lastItemName = currentPathDetails[currentPathDetails.length - 1].name;

    // Get the id of the last item in the currentPathDetails array
    const getLastItemId = currentPathDetails[currentPathDetails.length - 1].categoryId;

    const formatPrice = useCallback(
        (price: number) => formatNumber(price, "da-DK", { decimals: 2 }),
        []
    );

    // Extracting brand from props.specs
    let brand;
    const fieldGroups = props.fieldGroups;
    for (const fieldGroup of fieldGroups) {
        if (fieldGroup.name === "Specifikation") {
            brand = fieldGroup.fields.Brand;
            break;
        }
    }

    // GTM Tracking product viewed
    const trackProductEvent = useProductTracker({
        name: props.webName,
        price: formatPrice(props?.price?.priceWithoutVat),
        productNumber: props.entityId,
        currency: "DKK",
        category: category,
        brand: brand,
        listId: getLastItemId.toString(),
        listName: lastItemName,
        quantity: quantity,
    });

    useEffect(() => {
        if (props?.price?.priceWithoutVat && props?.price?.priceWithoutVat !== 0) {
            // GTM Tracking
            trackProductEvent("view_item");
            pushToDataLayer({
                event: "google_ads_remarketing",
                ecomm_pagetype: "product",
                ecomm_totalvalue: props?.price?.priceWithoutVat,
                ecomm_prodid: props.masterProductNumber,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props?.price?.priceWithoutVat]);

    // Shipping modal info
    const openShippingInfoModal = useCallback(() => {
        showModal(
            <DA_DeliveryInfoModalContent specialFreightOverlayText={specialFreightDescription} />,
            {
                size: ModalSize.XXL,
            }
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showModal]);

    // Shipping bottom sheet info
    const openDynamicOverlay = useCallback(() => {
        showOverlay({
            id: "delivery-filter_open",
            title: deliveryModalTitle,
            content: (
                <BottomSheetDeliveryInfo specialFreightOverlayText={specialFreightDescription} />
            ),
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showOverlay]);

    const extraInfoItems = useMemo(() => {
        const tabs = [];

        // Add tabs for each field group excluding "general"
        if (props.fieldGroups?.length) {
            props.fieldGroups.forEach((group) => {
                if (!group.hideOnWeb) {
                    if (group.layout === "Text") {
                        let textContent = "";

                        // Iterate over the keys of the fields object to find the appropriate content
                        Object.keys(group.fields).forEach((key) => {
                            textContent = group.fields[key];
                        });

                        tabs.push({
                            title: group.name,
                            content: (
                                <DA_ContentBox>
                                    {isMobileOrTablet ? (
                                        <>
                                            {hasQuantityOnPallet && group.id === "description" && (
                                                <div className={S.labelTagWrapper}>
                                                    <DA_Tag color="neutral" size="dynamic">
                                                        {getDictionaryItem("quantityonpallettext", {
                                                            quantity: quantityOnPalletValue,
                                                        })}
                                                    </DA_Tag>
                                                </div>
                                            )}
                                            <p
                                                dangerouslySetInnerHTML={{ __html: textContent }}
                                                className={S.productDescription}
                                            />
                                        </>
                                    ) : (
                                        <>
                                            {hasQuantityOnPallet && group.id === "description" && (
                                                <div className={S.labelTagWrapper}>
                                                    <DA_Tag color="neutral" size="dynamic">
                                                        {getDictionaryItem("quantityonpallettext", {
                                                            quantity: quantityOnPalletValue,
                                                        })}
                                                    </DA_Tag>
                                                </div>
                                            )}
                                            <DA_ProductDescription
                                                description={textContent}
                                                ref={productDescriptionRef}
                                            />
                                        </>
                                    )}
                                </DA_ContentBox>
                            ),
                        });
                    } else if (group.layout === "DocumentLinks") {
                        tabs.push({
                            title: group.name,
                            content: (
                                <DA_ContentBox>
                                    <div className={S.linkList}>
                                        {group.media?.map((document) => (
                                            <div className={S.linkListItem} key={document.url}>
                                                <DA_InlineLink
                                                    title={document.name}
                                                    href={document.url}
                                                    target="_blank"
                                                    icon={DA_IconNames.Pdf}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </DA_ContentBox>
                            ),
                        });
                    } else {
                        tabs.push({
                            title: group.name,
                            content: (
                                <DA_ContentBox>
                                    <table
                                        className={S.specifications}
                                        style={{
                                            width: "100%",
                                            borderCollapse: "collapse",
                                        }}
                                    >
                                        <tbody>
                                            {Object.entries(group.fields).map(
                                                ([key, value], index) => (
                                                    <tr
                                                        key={index}
                                                        style={{
                                                            background:
                                                                index % 2 === 0
                                                                    ? "#f5f5f5"
                                                                    : undefined,
                                                        }}
                                                    >
                                                        <td
                                                            className={S.tableFirstTd}
                                                            valign="top"
                                                            style={{
                                                                padding: "5px",
                                                                fontWeight: "bold",
                                                            }}
                                                        >
                                                            {key}
                                                        </td>
                                                        <td
                                                            valign="top"
                                                            style={{ padding: "5px" }}
                                                            dangerouslySetInnerHTML={{
                                                                __html: value,
                                                            }}
                                                        />
                                                    </tr>
                                                )
                                            )}
                                        </tbody>
                                    </table>
                                </DA_ContentBox>
                            ),
                        });
                    }
                }
            });
        }

        return tabs;
    }, [
        props.fieldGroups,
        isMobileOrTablet,
        hasQuantityOnPallet,
        getDictionaryItem,
        quantityOnPalletValue,
    ]);

    const scrollToExtraInfo = () => {
        // For Mobile or Tablet
        if (isMobileOrTablet) {
            if (descriptionAccordionRef.current) {
                descriptionAccordionRef.current.showFullDescription(); // Open the accordion
                descriptionAccordionRef.current.scrollIntoView(); // This should perform smooth scrolling
            }
        } else {
            // For Desktop
            if (tabRef.current) {
                tabRef.current.showDescriptionTab();
            }

            setTimeout(() => {
                if (productDescriptionRef.current) {
                    productDescriptionRef.current.showFullDescription();
                }
            }, 50);
        }
    };

    const calculateRangeOffset = (quantityOnPallet: number) => {
        return Math.floor(quantityOnPallet * 0.25); // ±25% of quantityOnPallet
    };

    const checkIfVariantIsSelected = useCallback(() => {
        if (Object.keys(selectedVariants).length < props.variantGroups.length) {
            setVariantsComplete(false);
        }
    }, [props.variantGroups.length, selectedVariants]);

    const latestQuantityRef = useRef(quantity);

    useEffect(() => {
        latestQuantityRef.current = quantity;
    }, [quantity]);

    useEffect(() => {
        const intValue = quantity;
        setModalViewState(modalState.hide);

        if (!isNaN(quantity)) {
            const rangeOffset = calculateRangeOffset(parseInt(quantityOnPalletValue));
            for (let numberOfPallets = 1; numberOfPallets <= 100; numberOfPallets++) {
                const totalPalletQuantity = numberOfPallets * parseInt(quantityOnPalletValue);
                const lowerBound = totalPalletQuantity - rangeOffset || 0;
                const upperBound = totalPalletQuantity + rangeOffset || 0;

                if (
                    intValue !== totalPalletQuantity &&
                    intValue >= lowerBound &&
                    intValue <= upperBound
                ) {
                    setModalViewState(modalState.show);
                    setNumberOfPallets(numberOfPallets);
                    setTotalPalletQuantity(totalPalletQuantity);
                    return;
                } else if (intValue === totalPalletQuantity) {
                    setModalViewState(modalState.hide);
                    return;
                }
            }
        }
    }, [quantity, quantityOnPalletValue]);

    const check = useCallback(
        (
            showModalState: modalState,
            defaultQuantityOnPallet: number,
            totalPalletQuantity: number,
            numberOfPallets: number,
            choosedValue: boolean
        ) => {
            return new Promise<number>((resolve) => {
                if (showModalState === modalState.show && !choosedValue) {
                    const handleSelection = (selectedValue) => {
                        setQuantity(selectedValue);
                        setChoosedValue(true);
                        resolve(selectedValue); // Resolve the promise after the state is updated with the new quantity
                    };

                    if (isMobileOrTablet) {
                        presentBottomSheet(
                            <DA_QuantityOnPalletOverlay
                                quantity={latestQuantityRef.current}
                                quantityOnPallet={totalPalletQuantity}
                                defaultQuantityOnPallet={defaultQuantityOnPallet}
                                numberOfPallets={numberOfPallets}
                                onSelection={handleSelection}
                            />,
                            {
                                id: "shop-quantity-bottom-sheet",
                                contentPadding: true,
                                headerTitle: getDictionaryString("shopquantityonpallettitle"),
                                snapPoints: ({ minHeight }) => [minHeight],
                                hideCloseButton: true,
                            }
                        );
                    } else {
                        showModal(
                            <DA_Modal title={getDictionaryString("shopquantityonpallettitle")}>
                                <DA_QuantityOnPalletOverlay
                                    quantity={latestQuantityRef.current}
                                    quantityOnPallet={totalPalletQuantity}
                                    defaultQuantityOnPallet={quantityAsInt}
                                    numberOfPallets={numberOfPallets}
                                    onSelection={handleSelection}
                                />
                            </DA_Modal>,
                            {
                                size: ModalSize.XS,
                                shouldCloseOnEsc: false,
                                shouldCloseOnOverlayClick: false,
                            }
                        );
                    }
                } else {
                    resolve(latestQuantityRef.current); // Resolve immediately if no modal is shown
                }
            });
            // eslint-disable-next-line react-hooks/exhaustive-deps
        },
        [getDictionaryString, isMobileOrTablet, presentBottomSheet, quantityAsInt, showModal]
    );

    const addProductToCart = useCallback(
        async (
            modalViewState: modalState,
            defaultQuantityOnPallet: number,
            totalPalletQuantity: number,
            numberOfPallets: number,
            choosedValue: boolean
        ) => {
            const selectedQuantity = await check(
                modalViewState,
                defaultQuantityOnPallet,
                totalPalletQuantity,
                numberOfPallets,
                choosedValue
            ); // Wait for check to complete and get the latest quantity

            if (Object.keys(selectedVariants).length < props.variantGroups.length) {
                setVariantsComplete(false);
            } else {
                setAwaitingResponse(true);
                // GTM Tracking
                trackProductEvent("add_to_cart");
                pushToDataLayer({
                    event: "google_ads_remarketing",
                    ecomm_pagetype: "product",
                    ecomm_totalvalue: parseFloat(priceWithTwoDecimalsString),
                    ecomm_prodid: props.entityId,
                });
                addToCart({
                    productId: props.variantEntityId,
                    quantity: selectedQuantity, // Use the latest quantity
                }).finally(() => setAwaitingResponse(false));
            }
        },
        [
            check,
            selectedVariants,
            props.variantGroups.length,
            props.entityId,
            props.variantEntityId,
            trackProductEvent,
            priceWithTwoDecimalsString,
            addToCart,
        ]
    );

    return (
        <>
            <div className={S.container} itemScope itemType="https://schema.org/Product">
                {isFarmInTimeEnabled &&
                    globalThis.isAuthenticated &&
                    isAvailableToBuy &&
                    !isMobileOrTablet && (
                        <div className={S.wishListButton}>
                            <DA_WishListButton
                                productId={props.entityId}
                                productName={props.webName}
                                productImage={image}
                                variantId={props.variantEntityId}
                                productPageUrl={productUrl}
                                isVariantSelected={() => checkIfVariantIsSelected()}
                                variantSelected={Object.keys(selectedVariants).length}
                                variantGroups={props.variantGroups.length}
                                quantityOnPallet={hasQuantityOnPallet ? quantityOnPalletValue : ""}
                            />
                        </div>
                    )}
                <div className={S.imageWrapper}>
                    <DA_ImageSlider
                        media={[
                            ...props.media.map((image) => ({
                                ...image,
                                alt: image.name,
                            })),
                        ]}
                        startOnVideos={startOnVideos}
                    />
                </div>

                <div className={S.priceSection}>
                    <div className={S.productNumber}>
                        {getDictionaryString("product number")} {props.masterProductNumber}
                        {isMobileOrTablet && props.stockStatus && (
                            <span className={S.stockMobileWrapper}>
                                <DA_StockStatus large comment={props.stockStatus} />
                            </span>
                        )}
                    </div>

                    {/* {props.newProduct && (
                        <div className={S.splashWrapper}>
                            <DA_NewProductSplash
                                text={getDictionaryString("new splat")}
                                className={S.newProductSplash}
                            />
                        </div>
                    )} */}

                    <div className={S.titleWrapper}>
                        <DA_Title h3 itemProp="name" inheritColor>
                            {props.webName}
                        </DA_Title>
                    </div>

                    {props.description ? (
                        <div className={S.descriptionTextWrapper}>
                            <div>
                                <div
                                    className={S.descriptionText}
                                    itemProp="description"
                                    dangerouslySetInnerHTML={{ __html: shortDescriptions }}
                                />
                                <DA_ButtonLink
                                    className={S.link}
                                    title={getDictionaryString("read more")}
                                    onClick={scrollToExtraInfo}
                                />
                            </div>
                        </div>
                    ) : null}

                    {optionGroups?.map((group, variantIndex) => {
                        const variantId = `variant-${variantIndex}`;

                        const sortedOptions = Object.values(group.options)
                            .sort((a, b) => (a.sortOrder ?? Infinity) - (b.sortOrder ?? Infinity))
                            .map((option) => ({
                                label: option.value,
                                value: option.value,
                                disabled: option.disabled,
                            }));

                        return isMobileOrTablet ? (
                            <div className={S.selectWrapper} key={group.name}>
                                {/* Wrap in the same input wrapper for consistency in style */}
                                <DA_InputWrapper forId={variantId}>
                                    {/* Use BottomSheetSelect for mobile */}
                                    <BottomSheetSelect
                                        options={sortedOptions}
                                        selectName={group.name}
                                        onChange={(option) =>
                                            handleVariantChange(group.name, option)
                                        }
                                        defaultValue={selectedVariants[group.name]}
                                        placeholder={`${getDictionaryString(
                                            "choose"
                                        )} ${group.name.toLocaleLowerCase()}`}
                                        // you can add more props as needed
                                    />
                                </DA_InputWrapper>
                            </div>
                        ) : (
                            <div className={S.selectWrapper} key={group.name}>
                                <DA_InputWrapper forId={group.name}>
                                    <DA_Select
                                        key={variantId}
                                        id={variantId}
                                        selectName={getDictionaryString(group.name)}
                                        options={sortedOptions}
                                        onChange={(option) =>
                                            handleVariantChange(group.name, option)
                                        }
                                        value={selectedVariants[group.name]} // Pass the selected value from state
                                        placeholder={`${getDictionaryString(
                                            "choose"
                                        )} ${group.name.toLowerCase()}`}
                                        isSearchable={false}
                                        inlineLabel
                                        fullWidth
                                        className={S.customSelect}
                                        // Apply custom prefix for classnames
                                        variantCount={Object.keys(group.options).length}
                                        disableOptionStyling
                                    />
                                </DA_InputWrapper>
                            </div>
                        );
                    })}

                    <DA_Text noPadding className={cn(S.helperText, !variantsComplete && S.show)}>
                        {getDictionaryString("selectVariant")}
                    </DA_Text>

                    {props.energyLabel ? (
                        <div className={S.energyWrapper}>
                            <DA_Tooltip
                                text={
                                    <div>
                                        <img
                                            className={S.energyLargeWrapper}
                                            src={props?.energyLabel?.labelUrl}
                                            alt="Energy label"
                                        />
                                    </div>
                                }
                            >
                                <img
                                    className={S.energyImage}
                                    src={props.energyLabel?.arrowUrl}
                                    alt="Energy mark"
                                />
                            </DA_Tooltip>
                            <DA_InlineLink
                                className={S.energyLabel}
                                href={props.energyLabel?.reportUrl}
                                title={getDictionaryString("ProductSheet")}
                                target="_blank"
                            />
                        </div>
                    ) : null}

                    {/** No product error message */}
                    <div className={cn(S.noProduct, noProduct && S.show)}>
                        {getDictionaryString("ProductPageProductNotAvailable")}
                    </div>
                    {/** Add to Cart Button */}
                    {isAvailableToBuy ? (
                        <>
                            {isMobileOrTablet ? (
                                <>
                                    <div
                                        className={cn(
                                            S.priceContainerMobile,
                                            !showPrices && S.priceDisabled
                                        )}
                                    >
                                        {showPrices && (
                                            <div className={S.offerWrapper}>
                                                {/* <span className={S.offerTab}>
                                                    Køb 3 for2 eller længere tekst
                                                </span> */}
                                            </div>
                                        )}

                                        <div className={S.priceWrapper}>
                                            {showPrices && (
                                                <div className={S.priceBox}>
                                                    <DA_Price
                                                        price={props.price}
                                                        priceLabel={getDictionaryString(
                                                            "unit price"
                                                        )}
                                                        comment={getDictionaryString("excl VAT")}
                                                        alternative
                                                    />
                                                </div>
                                            )}
                                            <div className={S.QuantityPickerBox}>
                                                <DA_QuantityPicker
                                                    onChange={setQuantity}
                                                    value={quantity}
                                                />
                                            </div>
                                        </div>
                                        <div className={S.cartBtnBox}>
                                            <DA_CartButton
                                                title={getDictionaryString("add to cart")}
                                                addToCart={() =>
                                                    addProductToCart(
                                                        modalViewState,
                                                        quantityAsInt,
                                                        totalPalletQuantity,
                                                        numberOfPallets,
                                                        choosedValue
                                                    )
                                                }
                                                showSpinner={awaitingResponse}
                                                disabled={noProduct}
                                                isAvailableToBuy={isAvailableToBuy}
                                                fullWidth
                                            />
                                        </div>
                                    </div>
                                    {isFarmInTimeEnabled &&
                                        globalThis.isAuthenticated &&
                                        isAvailableToBuy &&
                                        isMobileOrTablet && (
                                            <div className={S.wishListButtonList}>
                                                <DA_WishListButton
                                                    productId={props.entityId}
                                                    productName={props.webName}
                                                    productImage={image}
                                                    variantId={props.variantEntityId}
                                                    productPageUrl={productUrl}
                                                    isVariantSelected={() =>
                                                        checkIfVariantIsSelected()
                                                    }
                                                    variantSelected={
                                                        Object.keys(selectedVariants).length
                                                    }
                                                    variantGroups={props.variantGroups.length}
                                                    quantityOnPallet={
                                                        hasQuantityOnPallet
                                                            ? quantityOnPalletValue
                                                            : ""
                                                    }
                                                    isNotModal
                                                />
                                            </div>
                                        )}
                                </>
                            ) : (
                                <div className={S.priceContainer}>
                                    {props.stockStatus && (
                                        <div className={S.offerAndStockWrapper}>
                                            {/* <div className={S.offerWrapper}>
                                            <span className={S.offerTab}>
                                                Køb 3 for2 eller længere tekst
                                            </span>
                                        </div> */}
                                            <div className={S.stockWrapper}>
                                                {props.stockStatus && (
                                                    <DA_StockStatus
                                                        large
                                                        comment={props.stockStatus}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    )}

                                    <div className={S.priceBasics}>
                                        <div className={S.priceSpaceBetween}>
                                            {showPrices && (
                                                <DA_Price
                                                    price={props.price}
                                                    priceLabel={getDictionaryString("unit price")}
                                                    comment={getDictionaryString("excl VAT")}
                                                    alternative
                                                />
                                            )}
                                        </div>

                                        <div className={S.priceContainerOptions}>
                                            <DA_QuantityPicker
                                                onChange={setQuantity}
                                                value={quantity}
                                                compactOnMobile
                                            />
                                            <DA_CartButton
                                                title={getDictionaryString("add to cart")}
                                                addToCart={() =>
                                                    addProductToCart(
                                                        modalViewState,
                                                        quantityAsInt,
                                                        totalPalletQuantity,
                                                        numberOfPallets,
                                                        choosedValue
                                                    )
                                                }
                                                showSpinner={awaitingResponse}
                                                disabled={noProduct}
                                                isAvailableToBuy={isAvailableToBuy}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}

                            {showPrices && (
                                <div className={S.deliveryLink}>
                                    <DA_ButtonLink
                                        title={deliveryLinkText}
                                        onClick={
                                            isMobileOrTablet
                                                ? openDynamicOverlay
                                                : openShippingInfoModal
                                        }
                                    />
                                </div>
                            )}
                        </>
                    ) : null}

                    {!isAvailableToBuy ? (
                        <div className={S.priceContainer}>
                            <DA_Button
                                href={redirectUrl}
                                title={getDictionaryString("contact your dealer")}
                                icon={DA_IconNames.Pin}
                                fullWidth
                            />
                        </div>
                    ) : null}

                    {props.hazardDetails && (
                        <div className={S.hazardStatements}>
                            {props.hazardDetails.signalWords && (
                                <DA_Text bold noPadding>
                                    {props.hazardDetails.signalWords.join(", ")}
                                </DA_Text>
                            )}
                            {props.hazardDetails.hazardStatements && (
                                <ul className={S.hTextItems}>
                                    {props.hazardDetails.hazardStatements.map((text, index) => (
                                        <li key={index}>{text}</li>
                                    ))}
                                </ul>
                            )}
                            {props.hazardDetails.safetyDatasheetUrls &&
                                props.hazardDetails.safetyDatasheetUrls.map((item, index) => (
                                    <DA_InlineLink
                                        key={index}
                                        title={getDictionaryString("safetyDocument")}
                                        href={item}
                                    />
                                ))}
                            {props.hazardDetails.supplementalInformation && (
                                <div className={S.supplementalInformation}>
                                    {props.hazardDetails.supplementalInformation.map(
                                        (text, index) => (
                                            <DA_Text bold noPadding key={index}>
                                                {text}
                                            </DA_Text>
                                        )
                                    )}
                                </div>
                            )}
                            {props.hazardDetails.pictogramUrls && (
                                <div className={S.hazardPictograms}>
                                    {props.hazardDetails.pictogramUrls.map((pictogram, index) => (
                                        <DA_Image
                                            className={S.hazardPictogramImage}
                                            key={index}
                                            src={pictogram}
                                            alt=""
                                            role="presentation"
                                        />
                                    ))}
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>

            <div>
                {isMobileOrTablet ? (
                    extraInfoItems.map((item, index) => (
                        <DA_Accordion
                            key={index}
                            showSmallHeader
                            title={item.title}
                            showToggleIcons
                            className={S.extraInfoAccordion}
                            animation
                            ref={
                                item.title === getDictionaryString("description")
                                    ? descriptionAccordionRef
                                    : null
                            }
                        >
                            {item.content}
                        </DA_Accordion>
                    ))
                ) : (
                    <DA_Tab items={extraInfoItems} ref={tabRef} isQuickOrder={props.isQuickOrder} />
                )}
            </div>
            {props.useRelewiseRelatedProducts && relatedProducts && (
                <div className={S.relatedProducts}>
                    <ComponentErrorBoundary showNothing componentName="RelatedProductsSlider">
                        <DA_ProductItemSlider
                            title={getDictionaryString("PdpRelatedProductsTitle")}
                            items={relatedProducts?.map((product) => mapProduct(product))}
                        />
                    </ComponentErrorBoundary>
                </div>
            )}
        </>
    );
};
