import React, { useState } from "react";
import { DA_ContentBox } from "../../components/atoms/ContentBox/ContentBox.component";
import { DA_Spinner } from "../../components/atoms/Spinner/Spinner.component";
import { DA_TranslationType } from "../../interfaces/translations.interface";
import { ModalSize, useModal } from "../modal.context";

interface Item {
    alias: string;
    value: string;
    location: string;
}

const TranslationModalLoader = ({
    alias,
    type,
    onSave,
    dictionaryLocation,
    chooseLocation = false,
    isCreatingNew,
}: {
    alias: string;
    type: DA_TranslationType;
    onSave?: (updatedItem: Item) => void;
    dictionaryLocation?: string;
    chooseLocation?: boolean;
    isCreatingNew?: boolean;
}) => {
    const Component = React.lazy(() =>
        import(`../../components/organisms/TranslationModal/TranslationModal.component`).then(
            (module) => ({
                default: module["DA_TranslationModal"],
            })
        )
    );

    return (
        <React.Suspense
            fallback={
                <DA_ContentBox>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            padding: "50px",
                        }}
                    >
                        <DA_Spinner />
                    </div>
                </DA_ContentBox>
            }
        >
            <Component
                alias={alias}
                type={type}
                dictionaryLocation={dictionaryLocation}
                onSave={onSave}
                chooseLocation={chooseLocation}
                isCreatingNew={isCreatingNew}
            />
        </React.Suspense>
    );
};

interface EditButtonProps {
    alias: string;
    type: DA_TranslationType;
    alwaysVisible?: boolean;
    onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    onSave?: (updatedItem: Item) => void;
    dictionaryLocation?: string;
    chooseLocation?: boolean;
    isCreatingNew?: boolean;
}

export const _DangerousEditButton = ({
    alias,
    type,
    onSave,
    dictionaryLocation,
    chooseLocation = false,
    isCreatingNew,
    onClick,
    ...buttonProps
}: Omit<EditButtonProps, "alwaysVisible"> &
    Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, "type" | "onClick">): JSX.Element => {
    const { showModal } = useModal();

    const showEditorModal = (alias: string) =>
        showModal(
            <TranslationModalLoader
                alias={alias}
                type={type}
                dictionaryLocation={dictionaryLocation}
                chooseLocation={chooseLocation}
                onSave={onSave}
                isCreatingNew={isCreatingNew}
            />,
            {
                size: ModalSize.XXL,
            }
        );

    const handleOnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (!onClick || onClick(event)) {
            event.stopPropagation();
            showEditorModal(alias);
        }
    };

    return <button type="button" onClick={handleOnClick} tabIndex={-1} {...buttonProps} />;
};

export const _EditButton = ({
    alias,
    type,
    alwaysVisible,
    dictionaryLocation,
    chooseLocation = false,
    isCreatingNew,
}: EditButtonProps): JSX.Element => {
    const [hover, setHover] = useState(false);
    const [disabled, setDisabled] = useState(false);

    return (
        <_DangerousEditButton
            alias={alias}
            type={type}
            dictionaryLocation={dictionaryLocation}
            chooseLocation={chooseLocation}
            isCreatingNew={isCreatingNew}
            aria-hidden={true}
            onClick={(event) => {
                if (event.ctrlKey || event.metaKey) {
                    event.preventDefault();
                    if (confirm("Disable translation functionality?")) {
                        setDisabled(true);
                    }
                    return false;
                }
                return true;
            }}
            style={{
                appearance: "none",
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                margin: 0,
                padding: 0,
                opacity: !disabled && (alwaysVisible || hover) ? 0.333 : 0,
                pointerEvents: disabled ? "none" : "auto",
                background: "#FFAC1C",
                border: 0,
                cursor: "pointer",
                transition: "opacity .1s ease-out",
            }}
            onFocus={() => setHover(true)}
            onBlur={() => setHover(false)}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
        />
    );
};
