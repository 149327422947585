import { useCallback, useState } from "react";
import { useCart } from "@danishagro/shared/src/contexts/cart/cart.context";
import { useModal } from "@danishagro/shared/src/contexts/modal.context";
import { _CartItemProps } from "../Item/CartItem.component";

export const useMarkAll = () => {
    const { deleteMarkedItems } = useCart();
    const { closeModal } = useModal();

    // States
    const [showCheckboxes, setShowCheckboxes] = useState<boolean>(false);
    const [deletedItems, setDeletedItems] = useState<_CartItemProps[]>([]);

    const confirmDeleteItems = useCallback(
        (ids: string[]) => {
            deleteMarkedItems(ids)
                .then(() => {
                    setShowCheckboxes(false);
                    setDeletedItems([]);
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    closeModal();
                });
        },
        [closeModal, deleteMarkedItems]
    );

    const deleteItem = useCallback((item: _CartItemProps) => {
        setDeletedItems((prev) => {
            // Check if the item already exists in the array by comparing IDs
            if (prev.some((i) => i.id === item.id)) {
                // If it exists, remove it
                return prev.filter((i) => i.id !== item.id);
            } else {
                // If it doesn't exist, add it
                return [...prev, item];
            }
        });
    }, []);

    const cancelSelection = useCallback(() => {
        setDeletedItems([]);
        setShowCheckboxes(false);
    }, []);

    const markAllItems = useCallback((items: _CartItemProps[]) => {
        const allItem = items.map((item) => item);
        setDeletedItems(allItem); // Set all item IDs as selected
    }, []);

    return {
        confirmDeleteItems,
        showCheckboxes,
        setShowCheckboxes,
        deletedItems,
        setDeletedItems,
        deleteItem,
        cancelSelection,
        markAllItems,
    };
};
