import React from "react";
import { DA_Text } from "@danishagro/shared/src/components/atoms/Text/Text.component";
import { DA_Button } from "@danishagro/shared/src/components/atoms/Button/Button.component";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import { DA_Container } from "@danishagro/shared/src/components/atoms/Container/Container.component";
import S from "./DeleteItemsOverlay.module.scss";

interface DeleteItemsOverlayProps {
    onClick: () => void;
    onClose: () => void;
}

export const DA_DeleteItemsOverlay = (props: DeleteItemsOverlayProps) => {
    const { getDictionaryString } = useTranslations();

    return (
        <DA_Container>
            <DA_Text noPadding>{getDictionaryString("deleteItemsModal.text")}</DA_Text>

            <div className={S.buttonWrapper}>
                <DA_Button
                    isGhost
                    onClick={props.onClose}
                    title={getDictionaryString("deleteItemsModal.Button.Cancel")}
                />
                <DA_Button
                    onClick={props.onClick}
                    title={getDictionaryString("deleteItemsModal.Button.Confirm")}
                />
            </div>
        </DA_Container>
    );
};
