import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { DA_UspList } from "@organisms/UspList/UspList.component";
import { DA_IconNames } from "@danishagro/shared/src/components/atoms/Icon/Icon.component";
import { usePage } from "@danishagro/shared/src/contexts/page.context";
import { getPage } from "@helpers/getPage.helper";
import { DA_CategorySlider } from "@organisms/CategorySlider/CategorySlider.component";
import { DA_Hero } from "@danishagro/shared/src/components/organisms/Hero/Hero.component";
import ComponentErrorBoundary from "@danishagro/shared/src/components/molecules/ErrorBoundary/ComponentErrorBoundary.component";
import { DA_NewPageData } from "@interfaces/newPageData.interface";
import { DA_NewCategoriesModuleData } from "@interfaces/contentModules/newPageData/categories.interface";
import { DA_NewBlockSpotsModuleData } from "@interfaces/contentModules/newPageData/spots.interface";
import { getAbsoluteUrl } from "@danishagro/shared/src/components/organisms/SiteHeader/LanguageSelector/hooks/getAbsolutePath.hook";
import { DA_NewHeroModuleData } from "@danishagro/shared/src/interfaces/hero.interface";
import { DA_ProductItemSlider } from "@organisms/ProductItemSlider/ProductItemSlider.component";
import {
    PersonalProductRecommendationBuilder,
    PopularProductsBuilder,
    ProblemDetailsError,
    ProductResult,
    RecentlyViewedProductsBuilder,
} from "@relewise/client";
import { useRelewise } from "@danishagro/shared/src/contexts/relewise.context";
import { DA_NavigationPageSimple } from "@danishagro/shared/src/interfaces/navigationPage.interface";
import { ImageConfig } from "@danishagro/shared/src/content/imageConfigs/imageConfig.enum";

export const DA_HomePage = () => {
    const { setPageIsLoading, allPages } = usePage();
    const [pageData, setPageData] = useState<DA_NewPageData>();
    const [heroData, setHeroData] = useState<DA_NewHeroModuleData>();
    const [heroImage, setHeroImage] = useState(null);
    const [heroVideo, setHeroVideo] = useState(null);
    const [heroButton, setHeroButton] = useState(null);
    const [heroPosterStart, setHeroPosterStart] = useState(null);
    const { getRecommender, mapProduct, relewiseUser } = useRelewise();
    const [firstLevelPages, setFirstLevelPages] = useState<DA_NavigationPageSimple[]>();
    const [popularProducts, setPopularProducts] = useState<ProductResult[] | null | undefined>();
    const [recentlyViewedProducts, setRecentlyViewedProducts] = useState<
        ProductResult[] | null | undefined
    >();
    const [latestBoughtProducts, setLatestBoughtProducts] = useState<
        ProductResult[] | null | undefined
    >();
    const getFirstLevelPages = useCallback((pages: DA_NavigationPageSimple[]) => {
        const firstLevelPaths = pages.filter((item) => {
            const path = new URL(item.path); // Parse the URL
            const segments = path.pathname.split("/").filter(Boolean); // Split and remove empty segments
            return segments.length === 1 && item.imageUrl; // Keep only paths with one segment + imageUrl
        });
        setFirstLevelPages(firstLevelPaths);
    }, []);

    const settings = {
        language: "da",
        currency: "DKK",
        displayedAtLocation: "Frontpage",
        user: relewiseUser(),
    };

    const lastBoughtProductsBuilder = new PersonalProductRecommendationBuilder(settings)
        .setSelectedProductProperties({
            displayName: true,
            pricing: true,
            allData: true,
            brand: true,
            allVariants: true,
        })
        .setNumberOfRecommendations(10)
        .allowFillIfNecessaryToReachNumberOfRecommendations(false)
        .allowReplacingOfRecentlyShownRecommendations(false)
        .recommendVariant(true)
        .prioritizeDiversityBetweenRequests(false);

    const recentlyViewedProductsBuilder = new RecentlyViewedProductsBuilder(settings)
        .setSelectedProductProperties({
            displayName: true,
            pricing: true,
            allData: true,
            brand: true,
            allVariants: true,
        })
        .setNumberOfRecommendations(6)
        .allowFillIfNecessaryToReachNumberOfRecommendations(false)
        .allowReplacingOfRecentlyShownRecommendations(false)
        .recommendVariant(true)
        .prioritizeDiversityBetweenRequests(false);

    const popularProductsbuilder = new PopularProductsBuilder(settings)
        .setSelectedProductProperties({
            displayName: true,
            pricing: true,
            allData: true,
            brand: true,
            allVariants: true,
        })
        .setNumberOfRecommendations(6)
        .allowFillIfNecessaryToReachNumberOfRecommendations(false)
        .allowReplacingOfRecentlyShownRecommendations(false)
        .recommendVariant(true)
        .basedOn("MostViewed")
        .sinceMinutesAgo(30240)
        .prioritizeDiversityBetweenRequests(false);

    useEffect(() => {
        getFirstLevelPages(allPages);
    }, [getFirstLevelPages, allPages]);

    useEffect(() => {
        if (globalThis.isAuthenticated) {
            getRecommender()
                .recommendPersonalProducts(lastBoughtProductsBuilder.build())
                .then((result) => {
                    setLatestBoughtProducts(result?.recommendations);
                })
                .catch((error: ProblemDetailsError) => console.error(error));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (globalThis.isAuthenticated) {
            getRecommender()
                .recentlyViewedProducts(recentlyViewedProductsBuilder.build())
                .then((result) => {
                    setRecentlyViewedProducts(result?.recommendations);
                })
                .catch((error: ProblemDetailsError) => console.error(error));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        getRecommender()
            .recommendPopularProducts(popularProductsbuilder.build())
            .then((result) => {
                setPopularProducts(result?.recommendations);
            })
            .catch((error: ProblemDetailsError) => console.error(error));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getImageObject = (data: DA_NewHeroModuleData) => {
        const image = data?.props?.image;

        if (image && image?.props?.umbracoFile?.includes("assetID")) {
            const imageWidth: number = 1400;
            const imageHeight: number = 600;
            const imageQuality: number = 80;

            const digizuiteSrc = image?.props?.umbracoFile
                .replace("{QUALITY}", `${imageQuality}`)
                .replace("{WIDTH}", `${imageWidth}`)
                .replace("{HEIGHT}", `${imageHeight}`);

            return {
                url: digizuiteSrc,
                width: imageWidth,
                height: imageHeight,
                alt: image.name,
            };
        } else if (image && image?.props?.umbracoFile) {
            return {
                url: getAbsoluteUrl(image?.props?.umbracoFile),
                width: image.props?.umbracoWidth,
                height: image.props?.umbracoHeight,
                alt: image.name,
            };
        } else {
            return null; // or handle the case where no image is available
        }
    };

    const getVideoObject = (data: DA_NewHeroModuleData) => {
        const video = data?.props?.video;

        if (video && video.length > 0 && video[0]?.content?.props?.videoFile) {
            const videoContent = video[0].content;
            const videoProps = videoContent.props;
            const videoUrl = videoProps.videoFile.url;

            let posterStart = null;
            if (videoProps.posterStart) {
                const poster = videoProps.posterStart;
                posterStart = {
                    url: poster.url,
                    width: poster.props?.umbracoWidth
                        ? parseInt(poster.props.umbracoWidth)
                        : undefined,
                    height: poster.props?.umbracoHeight
                        ? parseInt(poster.props.umbracoHeight)
                        : undefined,
                    alt: poster.name,
                };
            }

            return {
                video: {
                    alias: videoContent.alias,
                    url: videoUrl,
                    autoplay: true,
                    controls: false,
                    muted: true,
                    loop: false,
                },
                posterStart: posterStart,
            };
        } else {
            return null;
        }
    };

    useEffect(() => {
        setPageIsLoading(true);

        // set hero data from global hero object
        const heroData = globalThis.heroObj;
        setHeroData(heroData);
        setHeroImage(getImageObject(heroData));
        setHeroButton(heroData?.props?.buttons?.[0]?.content?.props?.link || null);
        const videoData = getVideoObject(heroData);
        if (videoData) {
            setHeroVideo(videoData.video);
            setHeroPosterStart(videoData.posterStart);
        } else {
            setHeroVideo(null);
            setHeroPosterStart(null);
        }

        getPage()
            .then((page) => {
                setPageData(page);
                setPageIsLoading(false);
            })
            .catch((error) => {
                console.error("Error fetching page content:", error);
                setPageIsLoading(false);
            });
    }, [setPageIsLoading]);

    return (
        <>
            <Helmet>
                <title>{pageData?.name}</title>
                <meta name="description" content={pageData?.metaDescription} />
            </Helmet>

            <div className="frontpage">
                <div className="frontpage-content">
                    {/* hero */}

                    {heroData && (
                        <ComponentErrorBoundary showNothing componentName="Hero">
                            <DA_Hero
                                headline={heroData.props.headline || ""}
                                text={heroData.props.text || ""}
                                image={
                                    heroImage && {
                                        src: heroImage.url,
                                        width: heroImage.width,
                                        height: heroImage.height,
                                        alt: heroImage.alt,
                                    }
                                }
                                video={heroVideo}
                                posterStart={heroPosterStart}
                                link={{
                                    title: heroButton?.name || "",
                                    href: heroButton?.url || "",
                                    target: heroButton?.target || "_self",
                                    conventionalRouting: true,
                                }}
                                fullWidthMobile
                            />
                        </ComponentErrorBoundary>
                    )}

                    {/* kategorier */}
                    {pageData?.props?.modules?.map((item, index) => {
                        if (item.content.alias === "blockCategories" && firstLevelPages) {
                            const data = item as DA_NewCategoriesModuleData;

                            const createObject = (navigationObject: DA_NavigationPageSimple) => {
                                return {
                                    title: navigationObject.name,
                                    url: navigationObject.path,
                                    imageAltText: navigationObject.name,
                                    image: `${navigationObject.imageUrl}/${ImageConfig.CategoryImage}`,
                                };
                            };

                            return (
                                <div key={index}>
                                    <ComponentErrorBoundary
                                        showNothing
                                        componentName="Category Slider"
                                    >
                                        <DA_CategorySlider
                                            key={index}
                                            title={data.content.props.headline}
                                            items={firstLevelPages.map((page) =>
                                                createObject(page)
                                            )}
                                        />
                                    </ComponentErrorBoundary>
                                </div>
                            );
                        }

                        if (
                            item.content.alias === "blockProducts" &&
                            item.content.props.type === "Latest" &&
                            recentlyViewedProducts?.length > 0
                        ) {
                            return (
                                <div key={index}>
                                    <ComponentErrorBoundary
                                        showNothing
                                        componentName="LastSeenSlider"
                                    >
                                        <DA_ProductItemSlider
                                            key={item.content.key}
                                            title={item.content.props.headline}
                                            items={recentlyViewedProducts?.map((product) =>
                                                mapProduct(product)
                                            )}
                                        />
                                    </ComponentErrorBoundary>
                                </div>
                            );
                        }

                        if (
                            item.content.alias === "blockProducts" &&
                            item.content.props.type === "Popular" &&
                            popularProducts?.length > 0
                        ) {
                            return (
                                <div key={index}>
                                    <ComponentErrorBoundary
                                        showNothing
                                        componentName="MostSeenSlider"
                                    >
                                        <DA_ProductItemSlider
                                            key={item.content.key}
                                            title={item.content.props.headline}
                                            items={popularProducts?.map((product) =>
                                                mapProduct(product)
                                            )}
                                        />
                                    </ComponentErrorBoundary>
                                </div>
                            );
                        }

                        if (
                            item.content.alias === "blockProducts" &&
                            item.content.props.type === "Personal" &&
                            latestBoughtProducts?.length > 0
                        ) {
                            return (
                                <div key={index}>
                                    <ComponentErrorBoundary
                                        showNothing
                                        componentName="LatestBoughtProductSlider"
                                    >
                                        <DA_ProductItemSlider
                                            key={item.content.key}
                                            title={item.content.props.headline}
                                            items={latestBoughtProducts?.map((product) =>
                                                mapProduct(product)
                                            )}
                                        />
                                    </ComponentErrorBoundary>
                                </div>
                            );
                        }

                        if (item.content.alias === "blockSpots") {
                            const spotItem = item as DA_NewBlockSpotsModuleData;

                            return (
                                <div key={index}>
                                    <ComponentErrorBoundary
                                        showNothing
                                        componentName="USP list - spots"
                                    >
                                        <DA_UspList
                                            key={index}
                                            header={spotItem.content.props.headline}
                                            description={spotItem.content.props.text}
                                            items={spotItem.content.props?.items.map((item) => ({
                                                icon: item.content.props.media[0].content.props
                                                    .icon as DA_IconNames,
                                                text: item.content.props.headline,
                                                subText: item.content.props.text,
                                            }))}
                                            mobileFullWidth
                                        />
                                    </ComponentErrorBoundary>
                                </div>
                            );
                        }
                    })}
                </div>
            </div>
        </>
    );
};
