export class CustomError extends Error {
    response: Response;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    constructor(message: string, response: Response, data: any) {
        super(message);
        Object.setPrototypeOf(this, CustomError.prototype); // Necessary when extending built-ins
        this.name = "CustomError";
        this.response = response;
        this.data = data;
    }
}
