import React, { Fragment, useCallback, useMemo, useRef, useState } from "react";
import { cn } from "@danishagro/shared/src/helpers/classNames.helper";
import { adjustUrl } from "@danishagro/shared/src/helpers/adjustUrl.helper";
import { useSiteHeader } from "../../../../contexts/siteHeader.context";
import { useOnClickOutside } from "../../../../hooks/useOnClickOutside.hook";
import { DA_BasicLink } from "../../../atoms/BasicLink/BasicLink.component";
import { DA_NavigationPage } from "../../../../interfaces/navigationPage.interface";
import { useAppData } from "../../../../contexts/appData.context";
import { recursiveCheckCurrentPathDesktop } from "./helpers/recursiveCheckCurrentPathDesktop.helper";
import { _ColumnList } from "./ColumnList/ColumnList.component";
import { splitIntoChunks } from "./helpers/splitIntoChunks.helper";
import S from "./NavigationDesktop.module.scss";

export const DA_NavigationDesktop = () => {
    const [openMenuItem, setOpenMenuItem] = useState(-1);
    const { currentSite } = useAppData();
    const { headerMeasure, pageTree } = useSiteHeader();

    const navRef = useRef<HTMLDivElement>();
    const timeoutRef = useRef(null);

    const onMouseEnterItem = useCallback((itemIndex: number) => {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(() => setOpenMenuItem(itemIndex), 150);
    }, []);

    const onMouseLeaveItem = useCallback(() => {
        clearTimeout(timeoutRef.current);
    }, []);

    const onKeyDownItem = useCallback(
        (itemIndex: number, event: React.KeyboardEvent<HTMLButtonElement>) => {
            if (event.key === " " || event.key === "Enter") {
                setOpenMenuItem(openMenuItem === itemIndex ? -1 : itemIndex);
            }
        },
        [openMenuItem]
    );

    const onMouseEnterNav = useCallback(() => {
        clearTimeout(timeoutRef.current);
    }, []);

    const onMouseLeaveNav = useCallback(() => {
        clearTimeout(timeoutRef.current);
        if (openMenuItem > -1) {
            timeoutRef.current = setTimeout(() => setOpenMenuItem(-1), 400);
        }
    }, [openMenuItem]);

    useOnClickOutside(
        navRef,
        useCallback(() => {
            if (openMenuItem) {
                setOpenMenuItem(-1);
            }
        }, [openMenuItem])
    );

    const arrangeChildrenByColumns = useMemo(() => {
        const result = [];
        const columnAmount = 5;

        const data = (pageTree[openMenuItem]?.children ?? []) as DA_NavigationPage[];

        // Split columns depending on which have children
        const allWithChildren: DA_NavigationPage[] = [];
        const allWithoutChildren: DA_NavigationPage[] = [];
        data.forEach((x) => {
            // Skip pages that are hidden
            if (x.hideInNavigation) return;

            // Count children (ignore hidden)
            const childCount = (x.children || []).filter(
                (child) => typeof child !== "undefined" && !child.hideInNavigation
            ).length;

            // Push to relevant array
            if (childCount > 0) {
                allWithChildren.push(x);
            } else {
                allWithoutChildren.push(x);
            }
        });

        // Firstly add all the parents with children into columns
        result.push(...allWithChildren);

        // Calculate the remaining columns to spread the orphans across
        // Or else create one more column to spread them across
        const remainingColumns =
            columnAmount - result.length > 0 ? columnAmount - result.length : 1;
        const chunks = splitIntoChunks(allWithoutChildren, remainingColumns);

        chunks.forEach((chunk) => {
            result.push(chunk);
        });

        return result;
    }, [openMenuItem, pageTree]);

    // Mappers

    const currentItems = (pageTree as DA_NavigationPage[]).reduce(
        recursiveCheckCurrentPathDesktop,
        []
    );

    return (
        <nav
            className={S.navigationDesktop}
            ref={navRef}
            onMouseEnter={onMouseEnterNav}
            onMouseLeave={onMouseLeaveNav}
        >
            <ul className={cn(S.list, openMenuItem === -1 && S.menuClosed)}>
                {pageTree?.map((page, index) => {
                    const currentItem = currentItems?.[0];
                    const isCurrentClass =
                        currentItem && page.path === currentItem?.path ? S.current : undefined;

                    return page.hideInNavigation ? null : (
                        <li key={index} className={S.item}>
                            {/** Link with children */}

                            {currentSite === "CMS" ? (
                                <DA_BasicLink
                                    className={cn(
                                        S.link,
                                        index === openMenuItem && S.opened,
                                        isCurrentClass
                                    )}
                                    href={page.url || page.path}
                                    onMouseEnter={() => onMouseEnterItem(index)}
                                    onClick={() => setOpenMenuItem(-1)}
                                >
                                    {page.name}
                                </DA_BasicLink>
                            ) : (
                                <>
                                    {page.children && page.children.length > 0 ? (
                                        <button
                                            type="button"
                                            className={cn(
                                                S.link,
                                                S.isButton,
                                                index === openMenuItem && S.opened,
                                                isCurrentClass
                                            )}
                                            onMouseEnter={() => onMouseEnterItem(index)}
                                            onMouseLeave={onMouseLeaveItem}
                                            onKeyDown={(event) => onKeyDownItem(index, event)}
                                        >
                                            {page.name}
                                        </button>
                                    ) : null}

                                    {/** Direct Link */}
                                    {!page.children || page.children.length === 0 ? (
                                        <DA_BasicLink
                                            className={cn(
                                                S.link,
                                                index === openMenuItem && S.opened,
                                                isCurrentClass
                                            )}
                                            href={page.url || page.path}
                                            onMouseEnter={() => onMouseEnterItem(index)}
                                            onClick={() => setOpenMenuItem(-1)}
                                        >
                                            {page.name}
                                        </DA_BasicLink>
                                    ) : null}
                                </>
                            )}
                        </li>
                    );
                })}
            </ul>

            {openMenuItem > -1 && pageTree[openMenuItem].children?.length > 0 && (
                <div
                    className={S.megaMenuWrapper}
                    style={{
                        maxHeight: `calc(100vh - ${headerMeasure?.height || 0}px)`,
                    }}
                >
                    {/** Child columns */}
                    <ul className={S.megaMenuList}>
                        {(currentSite === "CMS"
                            ? pageTree[openMenuItem].children
                            : arrangeChildrenByColumns
                        ).map((page, level2index) => {
                            const currentItem = currentItems?.[1];
                            const isCurrentClass =
                                currentItem && page.path === currentItem?.path
                                    ? S.active
                                    : undefined;

                            // Determine the condition based on currentSite
                            const shouldRenderPage =
                                currentSite === "CMS"
                                    ? !page.hideInNavigation
                                    : !page.hideInNavigation && page.children?.[0];

                            return (
                                <Fragment key={level2index}>
                                    {/** Child with Children */}
                                    {shouldRenderPage && (
                                        <li className={S.column}>
                                            <DA_BasicLink
                                                href={adjustUrl(
                                                    page.metaUrl || page.url || page.path
                                                )}
                                                className={cn(S.columnTitle, isCurrentClass)}
                                                onClick={() => setOpenMenuItem(-1)}
                                            >
                                                {page.name}
                                            </DA_BasicLink>

                                            {page.children?.length > 0 && (
                                                <_ColumnList
                                                    parentLink={page.url || page.path}
                                                    pages={page.children}
                                                    currentItems={currentItems}
                                                    setOpenMenuItem={setOpenMenuItem}
                                                />
                                            )}
                                        </li>
                                    )}

                                    {/** Child with Orphans */}
                                    {Array.isArray(page) && page?.[0] ? (
                                        <li
                                            key={level2index}
                                            className={cn(S.column, S.orphanColumn)}
                                        >
                                            {page.map((orphan, orphanIndex: number) => {
                                                const isOrphanCurrentClass =
                                                    currentItem && orphan.path === currentItem?.path
                                                        ? S.active
                                                        : undefined;

                                                return (
                                                    /* DIV here to avoid full width link */
                                                    <div key={orphanIndex}>
                                                        <DA_BasicLink
                                                            href={orphan.url || orphan.path}
                                                            className={cn(
                                                                S.columnTitle,
                                                                isOrphanCurrentClass
                                                            )}
                                                            onClick={() => setOpenMenuItem(-1)}
                                                        >
                                                            {orphan.name}
                                                        </DA_BasicLink>
                                                    </div>
                                                );
                                            })}
                                        </li>
                                    ) : null}
                                </Fragment>
                            );
                        })}
                    </ul>
                </div>
            )}
        </nav>
    );
};
