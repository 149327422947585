import React from "react";
import { DA_BasicLink } from "@danishagro/shared/src/components/atoms/BasicLink/BasicLink.component";
import { DA_Image } from "@danishagro/shared/src/components/atoms/Image/Image.component";
import { DA_ProductTag } from "@molecules/ProductTag/ProductTag.component";
import { DA_Icon, DA_IconNames } from "@danishagro/shared/src/components/atoms/Icon/Icon.component";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import { DA_Text } from "@danishagro/shared/src/components/atoms/Text/Text.component";
import { useAppData } from "@danishagro/shared/src/contexts/appData.context";
import { B2bImageSrc } from "@danishagro/shared/src/helpers/imageSrc.helper";
import { ImageConfig } from "@danishagro/shared/src/content/imageConfigs/imageConfig.enum";
import { useCart } from "@danishagro/shared/src/contexts/cart/cart.context";
import { formatNumber } from "@danishagro/shared/src/helpers/formatNumber.helper";
import { DA_Checkbox } from "@danishagro/shared/src/components/atoms/Checkbox/Checkbox.component";
import { DA_CartItemMobileProps } from "./CartItemMobile.interface";
// Style
import S from "./CartItemMobile.module.scss";

export const DA_CartItemMobile = ({ ...props }: DA_CartItemMobileProps) => {
    const { getDictionaryString } = useTranslations();
    const { showPrices, currentCulture } = useAppData();
    const { disableCropProtectionDelivery } = useCart();

    return (
        <div className={S.mobilItem}>
            <div className={S.boxImage}>
                {/** Image */}
                <div className={S.imageWrapper}>
                    <DA_BasicLink href={props.url} className={S.imageLink}>
                        <DA_Image
                            src={B2bImageSrc(props.imageUrl, ImageConfig.PdpThumbnail)}
                            alt={props.sizedImageMobile.alt}
                            width={props.sizedImageMobile.width}
                            height={props.sizedImageMobile.height}
                            className={S.image}
                        />
                    </DA_BasicLink>
                </div>
            </div>
            <div className={S.boxInfo}>
                {/** Name */}
                <div className={S.numberAndName}>
                    <div className={S.name}>
                        <DA_BasicLink href={props.url} className={S.nameLink}>
                            {props.productName}
                        </DA_BasicLink>
                    </div>
                </div>

                {/** Delivery Date */}
                {showPrices && (
                    <div className={S.deliveryDate}>
                        <div className={S.deliveryDateInfo}>
                            <span className={S.dateLabel}>
                                {getDictionaryString("latest delivery date", {
                                    uppercaseFirst: true,
                                })}
                                : <span>{props.priceData.shippingDate}</span>
                            </span>
                        </div>

                        {props.data.allowCropProtectionDelivery && !props.hideDeliveryOptions ? (
                            <DA_ProductTag icon={DA_IconNames.PlantHand} color="green" size="small">
                                {getDictionaryString("allow crop protection delivery")}
                            </DA_ProductTag>
                        ) : null}

                        {props.data.allowExpressDelivery && !props.hideDeliveryOptions ? (
                            <DA_ProductTag icon={DA_IconNames.Truck} color="blue" size="small">
                                {getDictionaryString("allow express delivery")}
                            </DA_ProductTag>
                        ) : null}
                    </div>
                )}

                {/** Price */}
                <div className={S.total}>
                    <div className={S.totalAmount}>
                        {`${props.quantity} ${getDictionaryString("pcs")}`}
                    </div>
                    {showPrices && (
                        <>
                            <div className={S.totalPrice}>
                                {props.isUpdatingCart ? "Loading..." : props.priceData.price}
                            </div>

                            {props.priceData.charges.length > 0 && (
                                <div className={S.charges}>
                                    {props.priceData.charges.map((fee) => (
                                        <div key={fee.code}>
                                            {fee.title}: {fee.price}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </>
                    )}
                </div>

                {props.data.allowCropProtectionDelivery &&
                    !props.hideDeliveryOptions &&
                    disableCropProtectionDelivery && (
                        <span className={S.tag}>
                            {`${getDictionaryString("TotalWieght")}: ${
                                props.data.lineGrossWeight % 1 === 0
                                    ? formatNumber(props.data.lineGrossWeight, currentCulture, {
                                          decimals: 0,
                                      })
                                    : formatNumber(props.data.lineGrossWeight, currentCulture, {
                                          decimals: 2,
                                      })
                            } kg`}
                        </span>
                    )}
                {props.driverMessage && !props.farmInTimeReferenceId && (
                    <div className={S.driverMessageWrapper}>
                        <DA_Text noPadding smallText className={S.driverMessage}>
                            {getDictionaryString("driverMessageMobileCartTitle")}
                            {": "}
                            {props.driverMessage}
                        </DA_Text>
                    </div>
                )}
            </div>
            <div className={S.boxButton}>
                {props.showCheckboxes ? (
                    <DA_Checkbox
                        label=""
                        checked={props.checked}
                        className={S.checkItem}
                        onChange={() => props.onCheckboxChange(props.id)}
                        isMobile
                        redBackground
                    />
                ) : (
                    <button onClick={props.openProductDrawer}>
                        <DA_Icon
                            className={S.buttonIcon}
                            useSvgDimensions={true}
                            name={DA_IconNames.ArrowRight}
                        />
                    </button>
                )}
            </div>
        </div>
    );
};
