import React, { useEffect, useRef } from "react";
import { useScreen } from "../../../contexts/screen.context";
import { DA_FooterObject } from "../../../interfaces/footerObject.interface";
import { DA_DesktopFooter } from "./DesktopFooter/DesktopFooter.component";
import { DA_MobileFooter } from "./MobileFooter/MobileFooter.component";

export interface DA_SiteFooterData {
    data: DA_FooterObject;
}

export const DA_SiteFooter = ({ data }: DA_SiteFooterData) => {
    const { isMobile } = useScreen();
    const footerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (footerRef.current) {
            const rect = footerRef.current.getBoundingClientRect();
            document.documentElement.style.setProperty("--footer-height", `${rect.height}px`);
        }
    }, []);

    return data ? (
        <div ref={footerRef}>
            {isMobile ? <DA_MobileFooter data={data} /> : <DA_DesktopFooter data={data} />}
        </div>
    ) : null;
};
