import "@danishagro/shared/src/styles/global/main.scss";
import "react-loading-skeleton/dist/skeleton.css";
import React, { useEffect, useRef, useState } from "react";
import { AppDataProvider, useAppData } from "@danishagro/shared/src/contexts/appData.context";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import { CartProvider } from "@danishagro/shared/src/contexts/cart/cart.context";
import { DA_SimpleHeader } from "@danishagro/shared/src/components/organisms/SimpleHeader/SimpleHeader.component";
import { DA_SiteFooter } from "@danishagro/shared/src/components/organisms/SiteFooter/SiteFooter.component";
import { DA_SiteHeader } from "@danishagro/shared/src/components/organisms/SiteHeader/SiteHeader.component";
import { GoogleTagManager } from "@danishagro/shared/src/components/atoms/GoogleTagManager/GoogleTagManager.component";
import { Helmet } from "react-helmet";
import { ModalProvider, ModalSize, useModal } from "@danishagro/shared/src/contexts/modal.context";
import { PageProvider, usePage } from "@danishagro/shared/src/contexts/page.context";
import { SiteHeaderProvider } from "@danishagro/shared/src/contexts/siteHeader.context";
import { Toaster } from "react-hot-toast";
import { ScreenProvider, useScreen } from "@danishagro/shared/src/contexts/screen.context";
import { TranslationsProvider } from "@danishagro/shared/src/contexts/translations/translations.context";
import { BottomSheetProvider } from "@danishagro/shared/src/contexts/bottomSheet/bottomSheet.context";
import { NotificationsProvider } from "@danishagro/shared/src/components/organisms/SiteHeader/hooks/useNotifications.hook";
import { DA_ShopPicker } from "@organisms/ShopPicker/ShopPicker.component";
import { getUrl } from "@danishagro/shared/src/helpers/getUrl.helper";
import { isDevMode } from "@danishagro/shared/src/helpers/isDevMode.helper";
import { getGlobalVariables } from "@helpers/getGlobalVariables.helper";
import { DA_ErrorBoundary } from "@danishagro/shared/src/components/molecules/ErrorBoundary/ErrorBoundary.component";
import { DA_PageNotFound } from "@danishagro/shared/src/components/organisms/PageNotFound/PageNotFound.component";
import useTrackPageView from "@danishagro/shared/src/gtm/useTagManger";
import useTrackScrollDistance from "@danishagro/shared/src/gtm/useTrackScrollDistance ";
import { RelewiseProvider } from "@danishagro/shared/src/contexts/relewise.context";
import { DA_ReceiptPage } from "./pages/Receipt.page";
import { DA_PlaceholderPage } from "./pages/Placeholder.page";
import { DA_HomePage } from "./pages/Home.page";
import { DA_FarmInTimePage } from "./pages/FarmInTime.page";
import { DA_CheckoutPage } from "./pages/Checkout.page";
import { DA_CartPage } from "./pages/Cart.page";
import { DA_SteppedCheckoutPage } from "./pages/SteppedCheckoutPage/SteppedCheckout.page";
import { DA_FarmInTimeCheckoutPage } from "./pages/FarmInTimeCheckout.page";

const GlobalLogic = ({ children }: { children: React.ReactNode }): JSX.Element => {
    const { isScrollLocked, scrollTo } = useScreen();
    const { isModalShowing, showModal } = useModal();
    const { pageIsLoading } = usePage();

    const [shouldShowModal, setShouldShowModal] = useState<boolean>(false);

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const isCampaign = searchParams.get("campaign") === "true";

        // Check if it's a campaign URL immediately
        if (isCampaign) {
            localStorage.setItem(`b2bShop`, `accepted`);
        } else {
            // Only show the modal if not a campaign and other conditions are met
            if (
                !globalThis.isAuthenticated &&
                localStorage.getItem(`b2bShop`) !== `accepted` &&
                getUrl("b2cShop")
            ) {
                setShouldShowModal(true);
            }
        }
    }, []);

    useEffect(() => {
        if (shouldShowModal) {
            showModal(<DA_ShopPicker />, {
                shouldCloseOnOverlayClick: false,
                shouldCloseOnEsc: false,
                size: ModalSize.SM,
            });
        }
    }, [showModal, shouldShowModal]);

    useEffect(() => {
        if (isModalShowing || isScrollLocked) {
            // Enable scroll lock
            document.documentElement.classList.add("scroll-locked");
        } else {
            // Disable scroll lock
            document.documentElement.classList.remove("scroll-locked");
        }
    }, [isModalShowing, isScrollLocked]);

    useEffect(() => {
        if (pageIsLoading) {
            document.body.classList.add("page-is-loading");
        } else {
            document.body.classList.remove("page-is-loading");
            scrollTo(0, true);
        }
    }, [pageIsLoading, scrollTo]);

    return (
        <>
            {globalThis.googleTagManagerId && (
                <GoogleTagManager gtmKey={globalThis.googleTagManagerId} />
            )}

            <Toaster
                position="top-right"
                toastOptions={{
                    style: {
                        backgroundColor: "var(--color-grey-90)",
                    },
                    custom: {
                        duration: 1500,
                    },
                }}
                containerClassName="toaster"
            />
            {children}
        </>
    );
};

const Contexts = ({ children }: { children: React.ReactNode }): JSX.Element => {
    const { isAppReady, customerNumber } = useAppData();

    return isAppReady ? (
        <CartProvider customerNumber={customerNumber}>
            <NotificationsProvider>
                <PageProvider>
                    <ScreenProvider>
                        <ModalProvider>
                            <RelewiseProvider paginationPageSize={24}>
                                <BottomSheetProvider>
                                    <GlobalLogic>{children}</GlobalLogic>
                                </BottomSheetProvider>
                            </RelewiseProvider>
                        </ModalProvider>
                    </ScreenProvider>
                </PageProvider>
            </NotificationsProvider>
        </CartProvider>
    ) : null;
};

export const ContextWrapper = ({ children }: { children: React.ReactNode }): JSX.Element => (
    <AppDataProvider currentSite="B2B" getGlobalVariables={getGlobalVariables}>
        <TranslationsProvider>
            <DA_ErrorBoundary
                featureName="B2B"
                featureVersion="v0"
                errorComponent={
                    <div
                        style={{
                            background: "var(--color-grey-5)",
                            boxShadow: "var(--shadow-level-1)",
                        }}
                    >
                        <DA_PageNotFound code={500} />
                    </div>
                }
            >
                <Contexts>{children}</Contexts>
            </DA_ErrorBoundary>
        </TranslationsProvider>
    </AppDataProvider>
);

const RoutesComponent = () => {
    const { pageTree } = usePage();
    const { pathname } = useLocation();

    // GTM Tracking
    // TODO: Add pageContent.alias to B2B
    useTrackPageView(globalThis?.pageContent?.pageAlias);
    useTrackScrollDistance("scroll_distance");

    const customerServiceLinks = useRef(
        globalThis.footerObj?.columns?.[0]?.items?.filter(({ itemType }) => itemType === "link") ||
            []
    ).current;

    return (
        <>
            <Helmet>
                <meta
                    name="format-detection"
                    content="telephone=no,date=no,address=no,email=no,url=no"
                />
            </Helmet>
            <div className="page-container">
                <SiteHeaderProvider
                    pageTree={pageTree}
                    showServiceMenu={globalThis.apiGatewayUrl || isDevMode}
                >
                    {/** Header */}
                    {!/^\/checkout(\/.*)?$/.test(pathname) ? (
                        <DA_SiteHeader customerServiceLinks={customerServiceLinks} />
                    ) : null}

                    {/** Simple Header */}
                    {/^\/checkout(\/.*)?$/.test(pathname) ? <DA_SimpleHeader /> : null}
                </SiteHeaderProvider>

                <main id="main">
                    <Routes>
                        <Route path="/" element={<DA_HomePage />} />

                        <Route path="/cart" element={<DA_CartPage />} />
                        <Route path="/checkout" element={<DA_CheckoutPage />} />
                        <Route path="/checkout/:step" element={<DA_SteppedCheckoutPage />} />
                        <Route path="/receipt/:orderSecret" element={<DA_ReceiptPage />} />

                        <Route path="/bestillingsliste" element={<DA_FarmInTimePage />} />
                        <Route
                            path="/bestillingsliste/checkout"
                            element={<DA_FarmInTimeCheckoutPage />}
                        />

                        <Route path="*" element={<DA_PlaceholderPage />} />
                    </Routes>
                </main>

                <DA_SiteFooter data={globalThis.footerObj} />
            </div>
        </>
    );
};

export const App = () => (
    <Router>
        <ContextWrapper>
            <RoutesComponent />
        </ContextWrapper>
    </Router>
);
