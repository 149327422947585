import React, { useCallback, useMemo, useRef, useState, useEffect } from "react";
import { animated, useSpring } from "react-spring";
import { DA_CartOrderLine } from "@danishagro/shared/src/interfaces/cartOrderLine.interface";
import { DA_ConfirmModal } from "@danishagro/shared/src/components/molecules/ConfirmModal/ConfirmModal.component";
import { getB2bImage } from "@danishagro/shared/src/helpers/getB2bImage.helper";
import { ModalSize, useModal } from "@danishagro/shared/src/contexts/modal.context";
import { useCart } from "@danishagro/shared/src/contexts/cart/cart.context";
import { useDebounce, useMeasure } from "react-use";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import { DA_ButtonColor } from "@danishagro/shared/src/components/atoms/Button/Button.component";
import Skeleton from "react-loading-skeleton";
import { formatNumber } from "@danishagro/shared/src/helpers/formatNumber.helper";
import { useAppData } from "@danishagro/shared/src/contexts/appData.context";
import { format } from "date-fns";
import { useOrderLinesPriceData } from "@danishagro/shared/src/hooks/useOrderLinePriceData.hook";
import { DA_CartLinePrice } from "@danishagro/shared/src/interfaces/price.interface";
import { DA_ProductTag } from "@molecules/ProductTag/ProductTag.component";
import { useScreen } from "@danishagro/shared/src/contexts/screen.context";
import { useDynamicOverlay } from "@danishagro/shared/src/hooks/dynamicOverlay/useDynamicOverlay.hook";
import { cn } from "@danishagro/shared/src/helpers/classNames.helper";
import { DA_IconNames } from "@danishagro/shared/src/components/atoms/Icon/Icon.component";
import { DA_BasicLink } from "@danishagro/shared/src/components/atoms/BasicLink/BasicLink.component";
import { DA_Image } from "@danishagro/shared/src/components/atoms/Image/Image.component";
import { useProductTracker } from "@danishagro/shared/src/gtm/useProductsTracker";
import { B2bImageSrc } from "@danishagro/shared/src/helpers/imageSrc.helper";
import { ImageConfig } from "@danishagro/shared/src/content/imageConfigs/imageConfig.enum";
import { DA_CartItemDesktop } from "./ScreenSizes/CartItemDesktop/CartItemDesktop.component";
import { DA_CartItemMobile } from "./ScreenSizes/CartItemMobile/CartItemMobile.component";
import { DA_CartItemDrawer } from "./CartItemDrawer/CartItemDrawer.component";
import S from "./CartItem.module.scss";

export interface _CartItemProps extends DA_CartOrderLine {
    linePrice?: DA_CartLinePrice;
    dimmed?: boolean;
    readOnly?: boolean;
    hideDeliveryOptions?: boolean;
    showDeliveryText?: boolean;
    extraPadding?: boolean;
    showAlternativeCardItem?: boolean;
    hideDriverMessageButton?: boolean;
    farmInTimeReferenceId?: string;
    isReceiptPage?: boolean;
    showCheckboxes?: boolean;
    checked?: boolean;
    disableDeleteButtons?: boolean;
    onCheckboxChange?: () => void;
}

export const _CartItem = React.memo(
    ({
        dimmed,
        readOnly,
        hideDeliveryOptions,
        showDeliveryText,
        linePrice,
        hideDriverMessageButton,
        farmInTimeReferenceId,
        showAlternativeCardItem = false,
        isReceiptPage,
        showCheckboxes,
        checked,
        disableDeleteButtons,
        onCheckboxChange,
        ...data
    }: _CartItemProps) => {
        const { isMobile } = useScreen();

        const {
            id,
            productId,
            productVariantId,
            productNumber,
            productName,
            driverMessage,
            image,
            quantity: defaultQuantity,
            unit,
        } = data;

        const [squashed, setSquashed] = useState(false);
        const [isUpdating, setIsUpdating] = useState(false);
        const [isRemoving, setIsRemoving] = useState(false);
        const [quantity, setQuantity] = useState(defaultQuantity);
        const [localQuantity, setLocalQuantity] = useState(quantity);

        const { getDictionaryString } = useTranslations();
        const { currentCulture, showPrices } = useAppData();

        const {
            updateCart,
            removeFromCart,
            isUpdatingCart,
            expressDeliveryModes,
            priceData: cartPriceData,
        } = useCart();

        const { showModal } = useModal();
        const { showOverlay, closeOverlay } = useDynamicOverlay();
        const [itemRef, { height }] = useMeasure();
        const orderLinesPriceData = useOrderLinesPriceData();
        const controllerRef = useRef<AbortController | null>();
        const [overlayContent, setOverlayContent] = useState(null);
        const [overlayIsOpen, setOverlayIsOpen] = useState(false);
        const [currentDriverMessage, setCurrentDriverMessage] = useState<string>(driverMessage);

        const url = `/${data.url}`.replace(/^\/+/, "/");
        const sizedImage = getB2bImage(image, 85);
        const sizedImageMobile = getB2bImage(image, 100);

        const styles = useSpring({
            height: squashed ? 0 : height + 1,
            immediate: !squashed,
        });

        // Extract price for each line
        const priceInfo = cartPriceData?.lines.find((line) => line?.orderLineId === data?.id);

        // Extract the category from the URL
        const category = data?.url?.split("/").slice(0, -1).join("_").replace(/^_/, "");

        const formatPrice = useCallback(
            (price: number) => formatNumber(price, "da-DK", { decimals: 2 }),
            []
        );

        // // GTM Tracking product
        const trackProductEvent = useProductTracker({
            name: data.productName,
            price: priceInfo ? formatPrice(priceInfo.price.priceWithoutVat) : "0",
            productNumber: data.productNumber,
            currency: "DKK",
            quantity: data.quantity,
            category,
        });

        const priceData = useMemo(() => {
            if (!showPrices) {
                return {
                    shippingDate: "",
                    price: "0",
                    charges: [],
                    orderLineKvantum: null,
                    feeAndChargesPrice: null,
                    withoutFeeAndChargesPrice: null,
                };
            }

            const skeletons = {
                shippingDate: <Skeleton width={72} />,
                price: <Skeleton width={50} />,
                charges: [],
                orderLineKvantum: <Skeleton width={30} />,
                feeAndChargesPrice: <Skeleton width={30} />,
                withoutFeeAndChargesPrice: <Skeleton width={30} />,
            };

            if (isUpdatingCart) return skeletons;

            const priceData =
                linePrice || orderLinesPriceData.find((line) => line?.orderLineId === data.id);

            if (!priceData) return skeletons;
            const shippingDateOnj = new Date(priceData.requestedShippingDay);
            return {
                shippingDate:
                    shippingDateOnj.getTime() < 0
                        ? // TODO: Remove this, when price service returns a real shipping date
                          expressDeliveryModes.cropProtectionDeliveryMode === "111"
                            ? getDictionaryString("crop protection delivery same day")
                            : expressDeliveryModes.cropProtectionDeliveryMode === "112"
                            ? getDictionaryString("crop protection delivery day to day")
                            : "-"
                        : format(shippingDateOnj, "dd.MM.yyyy"),
                price: formatNumber(priceData.price.priceWithoutVat, currentCulture, {
                    decimals: 2,
                }),
                charges: priceData.charges
                    ? priceData.charges.map((fee) => ({
                          code: fee.chargeCode,
                          title: getDictionaryString(fee.chargeCode),
                          price: formatNumber(fee.totalCalculated, currentCulture, {
                              decimals: 2,
                          }),
                      }))
                    : [],
                orderLineKvantum:
                    priceData?.orderLineKvantum !== undefined &&
                    priceData?.orderLineKvantum !== null &&
                    priceData?.orderLineKvantum !== 0 ? (
                        <>
                            {formatNumber(priceData?.orderLineKvantum, currentCulture, {
                                decimals: 2,
                            })}
                        </>
                    ) : null,

                feeAndChargesPrice: {
                    priceWithoutVat: priceData?.feeAndChargesPrice?.priceWithoutVat,
                    priceWithVat: priceData?.feeAndChargesPrice?.priceWithVat,
                },
                withoutFeeAndChargesPrice: {
                    priceWithoutVat: priceData?.withoutFeeAndChargesPrice?.priceWithoutVat,
                    priceWithVat: priceData?.withoutFeeAndChargesPrice?.priceWithVat,
                },
            };
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [
            isUpdatingCart,
            linePrice,
            orderLinesPriceData,
            currentCulture,
            data.id,
            getDictionaryString,
            expressDeliveryModes,
            localQuantity,
        ]);

        const updateQuantity = useCallback(
            (newQuantity: number) => {
                setQuantity(newQuantity);
                if (!isUpdatingCart) {
                    setIsUpdating(true);
                }
            },
            [isUpdatingCart]
        );

        const updateQuantityDesktop = useCallback(
            (newQuantity: number) => {
                setQuantity(newQuantity);
                if (!isUpdatingCart) {
                    setIsUpdating(true);
                }
            },
            [isUpdatingCart]
        );

        useDebounce(
            () => {
                if (quantity !== defaultQuantity) {
                    if (controllerRef.current) {
                        // If a change has already been sent to the API,
                        // abort it before sending a new one
                        controllerRef.current.abort();
                    }

                    controllerRef.current = new AbortController();

                    setIsUpdating(true);
                    updateCart(
                        {
                            ...data,
                            quantity,
                        },
                        { signal: controllerRef.current.signal }
                    )
                        .catch(() => {
                            // Reset item
                            console.error("Error updating cart");
                            setQuantity(defaultQuantity);
                        })
                        .finally(() => setIsUpdating(false));
                } else {
                    setIsUpdating(false);
                }
            },
            500,
            [quantity]
        );

        const remove = useCallback(() => {
            setIsRemoving(true);
            // GTM Tracking
            trackProductEvent("remove_from_cart");
            removeFromCart(data)
                .then(() => {
                    // Remove item
                    if (isMobile) {
                        closeOverlay();
                        setOverlayIsOpen(false);
                    }
                    setSquashed(true);
                })
                .finally(() => setIsRemoving(false));
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [removeFromCart, data, closeOverlay]);

        const confirmRemove = useCallback(
            () =>
                showModal(
                    <DA_ConfirmModal
                        title={getDictionaryString("remove product")}
                        description={getDictionaryString("confirm removal of product")}
                        approveButtonLabel={getDictionaryString("remove")}
                        approveButtonColor={DA_ButtonColor.Alert}
                        onApprove={() => remove()}
                        denyButtonLabel={getDictionaryString("cancel")}
                    />,
                    { size: ModalSize.XS }
                ),
            [showModal, getDictionaryString, remove]
        );

        const updateDriverMessage = useCallback((newDriverMessage: string) => {
            setCurrentDriverMessage(newDriverMessage);
        }, []);

        const saveProduct = useCallback(
            (driverMessage: string) => {
                updateDriverMessage(driverMessage);
                // eslint-disable-next-line react-hooks/exhaustive-deps
            },
            [updateDriverMessage]
        );

        // Drawer for Mobile
        useEffect(() => {
            const overlayContent = (
                <DA_CartItemDrawer
                    productId={productId}
                    productVariantId={productVariantId}
                    driverMessage={currentDriverMessage}
                    setCurrentDriverMessage={setCurrentDriverMessage}
                    url={url}
                    sizedImageMobile={sizedImageMobile}
                    productNumber={productNumber}
                    productName={productName}
                    priceData={priceData}
                    readOnly={readOnly}
                    updateQuantity={updateQuantity}
                    localQuantity={localQuantity}
                    dimmed={dimmed}
                    quantity={quantity}
                    unit={unit}
                    currentCulture={currentCulture}
                    confirmRemove={confirmRemove}
                    saveProduct={saveProduct}
                    farmInTimeReferenceId={farmInTimeReferenceId}
                    isReceiptPage={isReceiptPage}
                    imageUrl={data.imageUrl}
                    data={data}
                    hideDeliveryOptions={hideDeliveryOptions}
                />
            );

            setOverlayContent(overlayContent);

            // If the overlay is currently open update the content
            if (overlayIsOpen) {
                closeOverlay();
                showOverlay({
                    id: "checkout-product-details-overlay-updated",
                    title: getDictionaryString("ProductDetails"),
                    content: overlayContent,
                });
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [
            readOnly,
            unit,
            quantity,
            overlayIsOpen,
            priceData,
            currentDriverMessage,
            setCurrentDriverMessage,
        ]);

        // Function to open the overlay
        const openProductDrawer = useCallback(() => {
            showOverlay({
                id: "checkout-product-details-overlay",
                title: getDictionaryString("ProductDetails"),
                content: overlayContent,
            });
            setOverlayIsOpen(true);
            setLocalQuantity(quantity);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [showOverlay, overlayContent]);

        return (
            <animated.div style={styles}>
                <div className={S.wrapper} ref={itemRef}>
                    <div className={cn(S.item, showAlternativeCardItem && S.mainInfoAltItem)}>
                        {isMobile ? (
                            showAlternativeCardItem ? (
                                <div className={S.mainInfoAlt}>
                                    <div className={S.imageWrapper}>
                                        <DA_BasicLink href={url} className={S.imageLink}>
                                            <DA_Image
                                                src={B2bImageSrc(
                                                    sizedImage.url,
                                                    ImageConfig.PdpThumbnail
                                                )}
                                                alt={sizedImage.alt}
                                                width={sizedImage.width}
                                                height={sizedImage.height}
                                                className={S.image}
                                            />
                                        </DA_BasicLink>
                                    </div>
                                    <div className={S.mainInfoAltContent}>
                                        {/** Name */}
                                        <div className={S.numberAndName}>
                                            <div className={S.name}>
                                                <DA_BasicLink href={url} className={S.nameLink}>
                                                    {productName}
                                                </DA_BasicLink>
                                            </div>
                                        </div>

                                        <div className={S.totalsWrapper}>
                                            {/** Amount */}
                                            <div className={S.actions}>
                                                <div className={S.amountInfo}>
                                                    {quantity} {unit}
                                                </div>
                                            </div>

                                            {/** Price */}
                                            {showPrices && (
                                                <div className={S.total}>
                                                    {priceData.price}

                                                    {priceData.charges.length > 0 && (
                                                        <div className={S.charges}>
                                                            {priceData.charges.map((fee) => (
                                                                <div key={fee.code}>
                                                                    {fee.title}: {fee.price}
                                                                </div>
                                                            ))}
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </div>

                                        {/** Delivery Date */}
                                        {showPrices && (
                                            <div className={S.deliveryDate}>
                                                <span
                                                    className={cn(
                                                        S.dateLabel,
                                                        showDeliveryText
                                                            ? S.showDateLabel
                                                            : S.hideDateLabel
                                                    )}
                                                >
                                                    {getDictionaryString("latest delivery date", {
                                                        uppercaseFirst: true,
                                                    })}
                                                    :{" "}
                                                </span>
                                                <span
                                                    className={showDeliveryText && S.priceDataSmall}
                                                >
                                                    {priceData.shippingDate}
                                                </span>

                                                {data.allowCropProtectionDelivery &&
                                                !hideDeliveryOptions ? (
                                                    <DA_ProductTag
                                                        icon={DA_IconNames.PlantHand}
                                                        color="green"
                                                        size="small"
                                                    >
                                                        {getDictionaryString(
                                                            "allow crop protection delivery"
                                                        )}
                                                    </DA_ProductTag>
                                                ) : null}

                                                {data.allowExpressDelivery &&
                                                !hideDeliveryOptions ? (
                                                    <DA_ProductTag
                                                        icon={DA_IconNames.Truck}
                                                        color="blue"
                                                        size="small"
                                                    >
                                                        {getDictionaryString(
                                                            "allow express delivery"
                                                        )}
                                                    </DA_ProductTag>
                                                ) : null}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ) : (
                                <DA_CartItemMobile
                                    id={id}
                                    url={url}
                                    sizedImageMobile={sizedImageMobile}
                                    productName={productName}
                                    priceData={{
                                        shippingDate: priceData.shippingDate,
                                        charges: priceData.charges,
                                        price: isUpdatingCart ? (
                                            <Skeleton width={50} />
                                        ) : (
                                            priceData.price
                                        ),
                                    }}
                                    driverMessage={currentDriverMessage}
                                    farmInTimeReferenceId={farmInTimeReferenceId}
                                    quantity={quantity}
                                    data={{
                                        allowCropProtectionDelivery:
                                            data.allowCropProtectionDelivery || false,
                                        allowExpressDelivery: data.allowExpressDelivery || false,
                                        lineGrossWeight: data.lineGrossWeight,
                                    }}
                                    hideDeliveryOptions={hideDeliveryOptions}
                                    openProductDrawer={openProductDrawer}
                                    imageUrl={data.imageUrl}
                                    showCheckboxes={showCheckboxes}
                                    onCheckboxChange={() => onCheckboxChange()}
                                    checked={checked}
                                />
                            )
                        ) : (
                            <DA_CartItemDesktop
                                id={data.id}
                                url={url}
                                imageUrl={data.imageUrl}
                                sizedImage={sizedImage}
                                productNumber={productNumber}
                                productName={productName}
                                productId={productId}
                                productVariantId={productVariantId}
                                quantity={quantity}
                                unit={unit}
                                driverMessage={driverMessage}
                                readOnly={readOnly}
                                dimmed={dimmed}
                                isUpdating={isUpdating}
                                isRemoving={isRemoving}
                                confirmRemove={confirmRemove}
                                priceData={priceData}
                                data={data}
                                hideDeliveryOptions={hideDeliveryOptions}
                                hideDriverMessageButton={hideDriverMessageButton}
                                farmInTimeReferenceId={farmInTimeReferenceId}
                                updateQuantityDesktop={updateQuantityDesktop}
                                disableDeleteButton={disableDeleteButtons}
                            />
                        )}
                    </div>
                </div>
            </animated.div>
        );
    }
);

_CartItem.displayName = "CartItem";
